import DashboardLayout from "@/pages/layouts/DashboardLayout";

let plasticSectorRoutes = {
  path: '/products',
  component: DashboardLayout,
  redirect: '/products/list',
  children: [
    {
      path: 'list',
      name: 'Product List',
      component: () => import(/* webpackChunkName: "plasticSectors" */  '@/pages/plasticSectors/PlasticSectorList'),
      meta: {
        permissionsCodes: ['plastic-sectors/index' ],
      }
    },
    {
      path: 'create',
      name: 'Add Product',
      component: () => import(/* webpackChunkName: "plasticSectors" */  '@/pages/plasticSectors/PlasticSectorForm'),
      meta: {
        permissionsCodes: ['plastic-sectors/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Product',
      component: () => import(/* webpackChunkName: "plasticSectors" */  '@/pages/plasticSectors/PlasticSectorForm'),
      meta: {
        permissionsCodes: ['plastic-sectors/update' ],
      }
    }
  ]
}

export default plasticSectorRoutes;
