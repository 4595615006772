import DashboardLayout from "@/pages/layouts/DashboardLayout";

let specsRoutes = {
  path: '/specs',
  component: DashboardLayout,
  redirect: '/specs/list',
  children: [
    {
      path: 'list',
      name: 'Specs List',
      component: () => import(/* webpackChunkName: "specs" */  '@/pages/specs/SpecsList'),
      meta: {
        permissionsCodes: ['specs/index' ],
      }
    },
    {
      path: 'create',
      name: 'Add Specs',
      component: () => import(/* webpackChunkName: "specs" */  '@/pages/specs/SpecsForm'),
      meta: {
        permissionsCodes: ['specs/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Specs',
      component: () => import(/* webpackChunkName: "specs" */  '@/pages/specs/SpecsForm'),
      meta: {
        permissionsCodes: ['specs/update' ],
      }
    }
  ]
}

export default specsRoutes;
