import DashboardLayout from "@/pages/layouts/DashboardLayout";

let collaborationRoutes = {
  path: '/collaborations',
  component: DashboardLayout,
  redirect: '/collaborations/list',
  children: [
    {
      path: 'list',
      name: 'Collaboration List',
      component: () => import(/* webpackChunkName: "collaboration" */  '@/pages/collaborations/CollaborationList'),
      meta: {
        permissionsCodes: ['collaborations/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Collaboration',
      component: () => import(/* webpackChunkName: "collaboration" */  '@/pages/collaborations/CollaborationForm'),
      meta: {
        permissionsCodes: ['collaborations/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Collaboration',
      component: () => import(/* webpackChunkName: "collaboration" */  '@/pages/collaborations/CollaborationForm'),
      meta: {
        permissionsCodes: ['collaborations/update' ],
      }
    }
  ]
}

export default collaborationRoutes;
