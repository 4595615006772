import DashboardLayout from "@/pages/layouts/DashboardLayout";

let plasticTypesRoutes = {
  path: '/plastic-types',
  component: DashboardLayout,
  redirect: '/plastic-types/list',
  children: [
    {
      path: 'list',
      name: 'Plastic Type List',
      component: () => import(/* webpackChunkName: "plasticTypes" */  '@/pages/plasticTypes/PlasticTypeList'),
      meta: {
        permissionsCodes: ['plastic-types/index' ],
      }
    },
    {
      path: 'create',
      name: 'Add Plastic Type',
      component: () => import(/* webpackChunkName: "plasticTypes" */  '@/pages/plasticTypes/PlasticTypeForm'),
      meta: {
        permissionsCodes: ['plastic-types/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Plastic Type',
      component: () => import(/* webpackChunkName: "plasticTypes" */  '@/pages/plasticTypes/PlasticTypeForm'),
      meta: {
        permissionsCodes: ['plastic-types/update' ],
      }
    }
  ]
}

export default plasticTypesRoutes;
