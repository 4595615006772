import DashboardLayout from "@/pages/layouts/DashboardLayout";

let faqRoutes = {
  path: '/FAQ',
  component: DashboardLayout,
  redirect: '/FAQ/list',
  children: [
    {
      path: 'list',
      name: 'FAQ List',
      component: () => import(/* webpackChunkName: "FAQ" */  '@/pages/faq/FAQList'),
      meta: {
        permissionsCodes: ['FAQ/index' ],
      }
    },
    {
      path: 'create',
      name: 'Add FAQ',
      component: () => import(/* webpackChunkName: "FAQ" */  '@/pages/faq/FAQForm'),
      meta: {
        permissionsCodes: ['FAQ/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit FAQ',
      component: () => import(/* webpackChunkName: "FAQ" */  '@/pages/faq/FAQForm'),
      meta: {
        permissionsCodes: ['FAQ/update' ],
      }
    }
  ]
}

export default faqRoutes;
