import DashboardLayout from "@/pages/layouts/DashboardLayout";

let recyclableRoutes = {
  path: '/recyclables',
  component: DashboardLayout,
  redirect: '/recyclables/list',
  children: [
    {
      path: 'list',
      name: 'Recyclables Gallery',
      component: () => import(/* webpackChunkName: "recyclables" */  '@/pages/recyclables/RecyclableList'),
      meta: {
        permissionsCodes: ['recyclables/get'],
      }
    },
    {
      path: 'manage',
      name: 'Recyclables Gallery',
      component: () => import(/* webpackChunkName: "recyclables" */  '@/pages/recyclables/RecyclableForm'),
      meta: {
        permissionsCodes: ['recyclables/save'],
      }
    },
  ]
}

export default recyclableRoutes;
