<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['workshop-gallery/get','recyclables/get', 'furniture/get'])"
                      :link="{name: 'Gallery', icon: 'nc-icon nc-camera-20'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['workshop-gallery/get'])"
                        :link="{name: 'Photo Gallery', path: '/photo-gallery/list'}">
          </sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['recyclables/get'])"
                        :link="{name: 'Recyclables Gallery', path: '/recyclables/list'}">
          </sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['furniture/get'])"
                        :link="{name: 'Furniture Gallery', path: '/furniture/list'}">
          </sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['products/index'])"
                      :link="{name: 'Projects', icon: 'nc-icon nc-refresh-02', path: '/projects/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['plastic-sectors/index'])"
                      :link="{name: 'Products', icon: 'nc-icon nc-grid-45', path: '/products/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['plastic-types/index'])"
                      :link="{name: 'Plastic Types', icon: 'nc-icon nc-bullet-list-67', path: '/plastic-types/list'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['team/index'])"
                      :link="{name: 'Team', icon: 'nc-icon nc-badge', path: '/team/list'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['collaboration-categories/index','collaborations/index'])"
                      :link="{name: 'Collaborations', icon: 'nc-icon nc-single-02'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['collaborations/index'])"
            :link="{name: 'Collaborations', path: '/collaborations/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['collaboration-categories/index'])"
            :link="{name: 'Category', path: '/collaboration-categories/list'}"></sidebar-item>

        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['specs/index'])"
                      :link="{name: 'Specs', icon: 'nc-icon nc-preferences-circle-rotate', path: '/specs/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['workshop-photo-gallery/index'])"
                      :link="{name: 'Workshop', icon: 'nc-icon nc-vector', path: '/workshop-photo-gallery/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['FAQ/index'])"
                      :link="{name: 'FAQ', icon: 'nc-icon nc-caps-small', path: '/FAQ/list'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['testimonials/index'])"
                      :link="{name: 'Testimonials', icon: 'nc-icon nc-quote', path: '/testimonials/list'}">
        </sidebar-item>






        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['contact-us/index'])"
                      :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>
<!--          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter/index'])"-->
<!--                        :link="{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}">-->
<!--          </sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['pages/index',
                                             'content-pages/create',
                                             'redirection-pages/create',
                                             'branches/index'])"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['pages/index'])"
            :link="{name: 'Existing Pages', path: '/pages/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['content-pages/create'])"
                        :link="{name: 'Add Content Page', path: '/content-pages/create'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create'])"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['branches/index'])"
            :link="{name: 'Branches', path: '/branches/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['panel-groups/index','panels/index'])"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panels/index'])"
            :link="{name: 'Panels', path: '/panels/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panel-groups/index'])"
            :link="{name: 'Groups', path: '/panel-groups/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['settings/index'])"
                      :link="{name: 'Settings', icon: 'nc-icon nc-settings-gear-64', path: '/settings/list'}">
        </sidebar-item>



        <!--</sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')
                            || $store.getters['auth/checkAccess']('users/deleted-users')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="false"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/deleted-users')"
                        :link="{name: 'Deleted Users', path: '/users/DeletedUsersList'}"></sidebar-item>

        </sidebar-item>


      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
