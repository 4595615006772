import DashboardLayout from "@/pages/layouts/DashboardLayout";

let furnitureRoutes = {
  path: '/furniture',
  component: DashboardLayout,
  redirect: '/furniture/list',
  children: [
    {
      path: 'list',
      name: 'Furniture Gallery',
      component: () => import(/* webpackChunkName: "furniture" */  '@/pages/furniture/FurnitureList'),
      meta: {
        permissionsCodes: ['furniture/get'],
      }
    },
    {
      path: 'manage',
      name: 'Furniture Gallery',
      component: () => import(/* webpackChunkName: "furniture" */  '@/pages/furniture/FurnitureForm'),
      meta: {
        permissionsCodes: ['furniture/save'],
      }
    },
  ]
}

export default furnitureRoutes;
