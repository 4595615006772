import DashboardLayout from "@/pages/layouts/DashboardLayout";

let collaborationCategoryRoutes = {
  path: '/collaboration-categories',
  component: DashboardLayout,
  redirect: '/collaboration-categories/list',
  children: [
    {
      path: 'list',
      name: 'Collaboration Category List',
      component: () => import(/* webpackChunkName: "collaborationCategory" */  '@/pages/collaborations/CategoryList'),
      meta: {
        permissionsCodes: ['collaboration-categories/index' ],
      }
    },
    {
      path: 'create',
      name: 'Add Collaboration Category',
      component: () => import(/* webpackChunkName: "collaborationCategory" */  '@/pages/collaborations/CategoryForm'),
      meta: {
        permissionsCodes: ['collaboration-categories/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Collaboration Category',
      component: () => import(/* webpackChunkName: "collaborationCategory" */  '@/pages/collaborations/CategoryForm'),
      meta: {
        permissionsCodes: ['collaboration-categories/update' ],
      }
    }
  ]
}

export default collaborationCategoryRoutes;
