import DashboardLayout from "@/pages/layouts/DashboardLayout";

let workshopGalleryRoutes = {
  path: '/photo-gallery',
  component: DashboardLayout,
  redirect: '/photo-gallery/list',
  children: [
    {
      path: 'list',
      name: 'Photo Gallery',
      component: () => import(/* webpackChunkName: "workshop gallery" */  '@/pages/workshop/WorkshopGalleryList'),
      meta: {
        permissionsCodes: ['workshop-gallery/get'],
      }
    },
    {
      path: 'manage',
      name: 'Photo Gallery',
      component: () => import(/* webpackChunkName: "workshop gallery" */  '@/pages/workshop/WorkshopGalleryForm'),
      meta: {
        permissionsCodes: ['workshop-gallery/save'],
      }
    },
  ]
}

export default workshopGalleryRoutes;
