import DashboardLayout from "@/pages/layouts/DashboardLayout";

let workshopPhotoGalleryRoutes = {
  path: '/workshop-photo-gallery',
  component: DashboardLayout,
  redirect: '/workshop-photo-gallery/list',
  children: [
    {
      path: 'list',
      name: 'Workshop List',
      component: () => import(/* webpackChunkName: "Workshop" */  '@/pages/workshopPhotoGallery/WorkshopPhotoGalleryList'),
      meta: {
        permissionsCodes: ['workshop-photo-gallery/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Workshop',
      component: () => import(/* webpackChunkName: "Workshop" */  '@/pages/workshopPhotoGallery/WorkshopPhotoGalleryForm'),
      meta: {
        permissionsCodes: ['workshop-photo-gallery/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Workshop',
      component: () => import(/* webpackChunkName: "Workshop" */  '@/pages/workshopPhotoGallery/WorkshopPhotoGalleryForm'),
      meta: {
        permissionsCodes: ['workshop-photo-gallery/update'],
      }
    }
  ]
}

export default workshopPhotoGalleryRoutes;
