import DashboardLayout from '@/pages/layouts/DashboardLayout.vue'
import NotFound from '@/pages/NotFoundPage.vue'
import Overview from '@/pages/Overview.vue'
import Login from '@/pages/authPages/Login.vue';
import rolesRoutes from "@/routes/rolesRoutes";
import permissionsRoutes from "@/routes/permissionsRoutes";
import usersRoutes from "@/routes/usersRoutes";
import branchesRoutes from "@/routes/pages/branchesRoutes";
import pagesRoutes from "@/routes/pages/pagesRoutes";
import redirectionRoutes from "@/routes/pages/redirectionPagesRoutes";
import contentRoutes from "@/routes/pages/contentPagesRoutes";
import panelGroupsRoutes from "@/routes/panels/panelGroupsRoutes";
import panelRoutes from "@/routes/panels/panelRoutes";
import settingsRoutes from "@/routes/general/settingsRoutes";
import UpdatePassword from "@/pages/profile/UpdatePassword";
import UpdateProfile from "@/pages/profile/UpdateProfile";
import ForgetPassword from "@/pages/authPages/ForgetPassword";
import ResetPassword from "@/pages/authPages/ResetPassword";
import newsletterRoutes from "@/routes/general/newsletterRoutes";
import contactusRoutes from "./general/contactusRoutes";
import mediaInfoRoutes from "@/routes/general/mediaInfoRoutes";
import teamRoutes from "./team/teamRoutes";
import collaborationCategoryRoutes from "./collaborations/collaborationCategoryRoutes";
import collaborationRoutes from "./collaborations/collaborationRoutes";
import workshopGalleryRoutes from "./workshop/workshopGalleryRoutes";
import productRoutes from "./products/productRoutes";
import plasticTypesRoutes from "./plastictTypes/plasticTypesRoutes";
import recyclableRoutes from "./recyclables/recyclableRoutes";
import specsRoutes from "./specs/specsRoutes";
import faqRoutes from "./faq/faqRoutes";
import testimonialRoutes from "./testimonials/testimonialRoutes";
import plasticSectorRoutes from "./plastictSectors/plasticSectorRoutes";
import workshopPhotoGalleryRoutes from "./workshopPhotoGallery/workshopPhotoGalleryRoutes";
import furnitureRoutes from "./furniture/furnitureRoutes";


let dashboardRoutes = {
  path: '/',
  component: DashboardLayout,
  redirect: '/overview',
  children: [
    {
      path: 'overview',
      name: 'Overview',
      component: Overview,
    },
    {
      path: 'change-my-password',
      name: 'Update Password',
      component: UpdatePassword,
    },
    {
      path: 'update-profile',
      name: 'Update Profile',
      component: UpdateProfile,
    },

  ],

};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
};
let forgetPasswordPage = {
  path: '/forget-password',
  name: 'Forget Password',
  component: ForgetPassword
};

let resetPassword = {
  path: '/reset-password/:token',
  name: 'Reset Password',
  component: ResetPassword
};

const routes = [
  {
    path: '/',
    redirect: '/overview'
  },
  loginPage,
  forgetPasswordPage,
  resetPassword,
  rolesRoutes,
  permissionsRoutes,
  redirectionRoutes,
  usersRoutes,
  branchesRoutes,
  contentRoutes,
  pagesRoutes,
  panelGroupsRoutes,
  panelRoutes,
  settingsRoutes,
  newsletterRoutes,
  contactusRoutes,
  dashboardRoutes,
  mediaInfoRoutes,
  teamRoutes,
  collaborationCategoryRoutes,
  collaborationRoutes,
  workshopGalleryRoutes,
  productRoutes,
  plasticTypesRoutes,
  recyclableRoutes,
  specsRoutes,
  faqRoutes,
  testimonialRoutes,
  plasticSectorRoutes,
  workshopPhotoGalleryRoutes,
  furnitureRoutes,
  {path: '*', name: 'Not Found', component: NotFound}
]

export default routes
