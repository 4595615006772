import DashboardLayout from "@/pages/layouts/DashboardLayout";

let productRoutes = {
  path: '/projects',
  component: DashboardLayout,
  redirect: '/projects/list',
  children: [
    {
      path: 'list',
      name: 'Project List',
      component: () => import(/* webpackChunkName: "products" */  '@/pages/products/ProductList'),
      meta: {
        permissionsCodes: ['products/index' ],
      }
    },
    {
      path: 'create',
      name: 'Add Project',
      component: () => import(/* webpackChunkName: "products" */  '@/pages/products/ProductForm'),
      meta: {
        permissionsCodes: ['products/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Project',
      component: () => import(/* webpackChunkName: "products" */  '@/pages/products/ProductForm'),
      meta: {
        permissionsCodes: ['products/update' ],
      }
    }
  ]
}

export default productRoutes;
